exports.components = {
  "component---src-pages-30-critical-illness-cover-js": () => import("./../../../src/pages/30+critical-illness-cover.js" /* webpackChunkName: "component---src-pages-30-critical-illness-cover-js" */),
  "component---src-pages-30-critical-illness-insurance-js": () => import("./../../../src/pages/30+critical-illness-insurance.js" /* webpackChunkName: "component---src-pages-30-critical-illness-insurance-js" */),
  "component---src-pages-30-critical-illness-js": () => import("./../../../src/pages/30+critical-illness.js" /* webpackChunkName: "component---src-pages-30-critical-illness-js" */),
  "component---src-pages-30-critical-illness-life-js": () => import("./../../../src/pages/30+critical-illness-life.js" /* webpackChunkName: "component---src-pages-30-critical-illness-life-js" */),
  "component---src-pages-30-criticalillness-cover-js": () => import("./../../../src/pages/30-criticalillness-cover.js" /* webpackChunkName: "component---src-pages-30-criticalillness-cover-js" */),
  "component---src-pages-30-criticalillness-insurance-js": () => import("./../../../src/pages/30-criticalillness-insurance.js" /* webpackChunkName: "component---src-pages-30-criticalillness-insurance-js" */),
  "component---src-pages-30-criticalillness-js": () => import("./../../../src/pages/30-criticalillness.js" /* webpackChunkName: "component---src-pages-30-criticalillness-js" */),
  "component---src-pages-30-criticalillness-life-js": () => import("./../../../src/pages/30-criticalillness-life.js" /* webpackChunkName: "component---src-pages-30-criticalillness-life-js" */),
  "component---src-pages-30-life-insurance-js": () => import("./../../../src/pages/30+life-insurance.js" /* webpackChunkName: "component---src-pages-30-life-insurance-js" */),
  "component---src-pages-30-lifeinsurance-js": () => import("./../../../src/pages/30-lifeinsurance.js" /* webpackChunkName: "component---src-pages-30-lifeinsurance-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-50-critical-illness-cover-js": () => import("./../../../src/pages/50+critical-illness-cover.js" /* webpackChunkName: "component---src-pages-50-critical-illness-cover-js" */),
  "component---src-pages-50-critical-illness-insurance-js": () => import("./../../../src/pages/50+critical-illness-insurance.js" /* webpackChunkName: "component---src-pages-50-critical-illness-insurance-js" */),
  "component---src-pages-50-critical-illness-js": () => import("./../../../src/pages/50+critical-illness.js" /* webpackChunkName: "component---src-pages-50-critical-illness-js" */),
  "component---src-pages-50-critical-illness-life-js": () => import("./../../../src/pages/50+critical-illness-life.js" /* webpackChunkName: "component---src-pages-50-critical-illness-life-js" */),
  "component---src-pages-50-life-insurance-js": () => import("./../../../src/pages/50+life-insurance.js" /* webpackChunkName: "component---src-pages-50-life-insurance-js" */),
  "component---src-pages-blinkx-vb-mjq-js": () => import("./../../../src/pages/blinkxVBMjq.js" /* webpackChunkName: "component---src-pages-blinkx-vb-mjq-js" */),
  "component---src-pages-burial-expense-insurance-js": () => import("./../../../src/pages/burial-expense-insurance.js" /* webpackChunkName: "component---src-pages-burial-expense-insurance-js" */),
  "component---src-pages-cancer-insurance-js": () => import("./../../../src/pages/cancer-insurance.js" /* webpackChunkName: "component---src-pages-cancer-insurance-js" */),
  "component---src-pages-cheap-lifeinsurance-js": () => import("./../../../src/pages/cheap-lifeinsurance.js" /* webpackChunkName: "component---src-pages-cheap-lifeinsurance-js" */),
  "component---src-pages-cheapest-lifeinsurance-js": () => import("./../../../src/pages/cheapest-lifeinsurance.js" /* webpackChunkName: "component---src-pages-cheapest-lifeinsurance-js" */),
  "component---src-pages-combined-critical-illness-life-js": () => import("./../../../src/pages/combined-critical-illness-life.js" /* webpackChunkName: "component---src-pages-combined-critical-illness-life-js" */),
  "component---src-pages-compare-and-save-js": () => import("./../../../src/pages/compare-and-save.js" /* webpackChunkName: "component---src-pages-compare-and-save-js" */),
  "component---src-pages-compare-critical-illness-cover-js": () => import("./../../../src/pages/compare-critical-illness-cover.js" /* webpackChunkName: "component---src-pages-compare-critical-illness-cover-js" */),
  "component---src-pages-compare-critical-illness-insurance-js": () => import("./../../../src/pages/compare-critical-illness-insurance.js" /* webpackChunkName: "component---src-pages-compare-critical-illness-insurance-js" */),
  "component---src-pages-compare-critical-illness-js": () => import("./../../../src/pages/compare-critical-illness.js" /* webpackChunkName: "component---src-pages-compare-critical-illness-js" */),
  "component---src-pages-compare-lifeinsurance-js": () => import("./../../../src/pages/compare-lifeinsurance.js" /* webpackChunkName: "component---src-pages-compare-lifeinsurance-js" */),
  "component---src-pages-compare-success-appointment-js": () => import("./../../../src/pages/compare-success-appointment.js" /* webpackChunkName: "component---src-pages-compare-success-appointment-js" */),
  "component---src-pages-compare-success-js": () => import("./../../../src/pages/compare-success.js" /* webpackChunkName: "component---src-pages-compare-success-js" */),
  "component---src-pages-critical-illness-cover-js": () => import("./../../../src/pages/critical-illness-cover.js" /* webpackChunkName: "component---src-pages-critical-illness-cover-js" */),
  "component---src-pages-critical-illness-insurance-js": () => import("./../../../src/pages/critical-illness-insurance.js" /* webpackChunkName: "component---src-pages-critical-illness-insurance-js" */),
  "component---src-pages-critical-illness-js": () => import("./../../../src/pages/critical-illness.js" /* webpackChunkName: "component---src-pages-critical-illness-js" */),
  "component---src-pages-dads-life-insurance-js": () => import("./../../../src/pages/dads-life-insurance.js" /* webpackChunkName: "component---src-pages-dads-life-insurance-js" */),
  "component---src-pages-decreasing-lifeinsurance-js": () => import("./../../../src/pages/decreasing-lifeinsurance.js" /* webpackChunkName: "component---src-pages-decreasing-lifeinsurance-js" */),
  "component---src-pages-decreasing-term-life-insurance-js": () => import("./../../../src/pages/decreasing-term-life-insurance.js" /* webpackChunkName: "component---src-pages-decreasing-term-life-insurance-js" */),
  "component---src-pages-family-life-insurance-js": () => import("./../../../src/pages/family-life-insurance.js" /* webpackChunkName: "component---src-pages-family-life-insurance-js" */),
  "component---src-pages-guaranteed-life-insurance-js": () => import("./../../../src/pages/guaranteed-life-insurance.js" /* webpackChunkName: "component---src-pages-guaranteed-life-insurance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inheritance-tax-insurance-js": () => import("./../../../src/pages/inheritance-tax-insurance.js" /* webpackChunkName: "component---src-pages-inheritance-tax-insurance-js" */),
  "component---src-pages-inheritance-tax-life-insurance-js": () => import("./../../../src/pages/inheritance-tax-life-insurance.js" /* webpackChunkName: "component---src-pages-inheritance-tax-life-insurance-js" */),
  "component---src-pages-joint-critical-illness-cover-js": () => import("./../../../src/pages/joint-critical-illness-cover.js" /* webpackChunkName: "component---src-pages-joint-critical-illness-cover-js" */),
  "component---src-pages-joint-critical-illness-insurance-js": () => import("./../../../src/pages/joint-critical-illness-insurance.js" /* webpackChunkName: "component---src-pages-joint-critical-illness-insurance-js" */),
  "component---src-pages-joint-critical-illness-js": () => import("./../../../src/pages/joint-critical-illness.js" /* webpackChunkName: "component---src-pages-joint-critical-illness-js" */),
  "component---src-pages-joint-critical-illness-life-js": () => import("./../../../src/pages/joint-critical-illness-life.js" /* webpackChunkName: "component---src-pages-joint-critical-illness-life-js" */),
  "component---src-pages-joint-lifeinsurance-js": () => import("./../../../src/pages/joint-lifeinsurance.js" /* webpackChunkName: "component---src-pages-joint-lifeinsurance-js" */),
  "component---src-pages-joint-over-50-life-insurance-js": () => import("./../../../src/pages/joint-over-50-life-insurance.js" /* webpackChunkName: "component---src-pages-joint-over-50-life-insurance-js" */),
  "component---src-pages-life-and-critical-illness-js": () => import("./../../../src/pages/life-and-critical-illness.js" /* webpackChunkName: "component---src-pages-life-and-critical-illness-js" */),
  "component---src-pages-life-assurance-js": () => import("./../../../src/pages/life-assurance.js" /* webpackChunkName: "component---src-pages-life-assurance-js" */),
  "component---src-pages-life-cover-insurance-js": () => import("./../../../src/pages/life-cover-insurance.js" /* webpackChunkName: "component---src-pages-life-cover-insurance-js" */),
  "component---src-pages-life-cover-js": () => import("./../../../src/pages/life-cover.js" /* webpackChunkName: "component---src-pages-life-cover-js" */),
  "component---src-pages-life-insurance-cover-js": () => import("./../../../src/pages/life-insurance-cover.js" /* webpackChunkName: "component---src-pages-life-insurance-cover-js" */),
  "component---src-pages-life-insurance-for-dads-js": () => import("./../../../src/pages/life-insurance-for-dads.js" /* webpackChunkName: "component---src-pages-life-insurance-for-dads-js" */),
  "component---src-pages-life-insurance-for-men-js": () => import("./../../../src/pages/life-insurance-for-men.js" /* webpackChunkName: "component---src-pages-life-insurance-for-men-js" */),
  "component---src-pages-life-insurance-for-mums-js": () => import("./../../../src/pages/life-insurance-for-mums.js" /* webpackChunkName: "component---src-pages-life-insurance-for-mums-js" */),
  "component---src-pages-life-insurance-for-parents-js": () => import("./../../../src/pages/life-insurance-for-parents.js" /* webpackChunkName: "component---src-pages-life-insurance-for-parents-js" */),
  "component---src-pages-life-insurance-for-women-js": () => import("./../../../src/pages/life-insurance-for-women.js" /* webpackChunkName: "component---src-pages-life-insurance-for-women-js" */),
  "component---src-pages-life-insurance-js": () => import("./../../../src/pages/life-insurance.js" /* webpackChunkName: "component---src-pages-life-insurance-js" */),
  "component---src-pages-life-insurance-ni-js": () => import("./../../../src/pages/life-insurance-ni.js" /* webpackChunkName: "component---src-pages-life-insurance-ni-js" */),
  "component---src-pages-life-insurance-over-70-js": () => import("./../../../src/pages/life-insurance-over-70.js" /* webpackChunkName: "component---src-pages-life-insurance-over-70-js" */),
  "component---src-pages-life-insurance-scotland-js": () => import("./../../../src/pages/life-insurance-scotland.js" /* webpackChunkName: "component---src-pages-life-insurance-scotland-js" */),
  "component---src-pages-life-insurance-united-kingdom-js": () => import("./../../../src/pages/life-insurance-united-kingdom.js" /* webpackChunkName: "component---src-pages-life-insurance-united-kingdom-js" */),
  "component---src-pages-life-insurance-voucher-js": () => import("./../../../src/pages/life-insurance-voucher.js" /* webpackChunkName: "component---src-pages-life-insurance-voucher-js" */),
  "component---src-pages-life-insurance-wales-js": () => import("./../../../src/pages/life-insurance-wales.js" /* webpackChunkName: "component---src-pages-life-insurance-wales-js" */),
  "component---src-pages-life-insurance-with-diabetes-js": () => import("./../../../src/pages/life-insurance-with-diabetes.js" /* webpackChunkName: "component---src-pages-life-insurance-with-diabetes-js" */),
  "component---src-pages-life-insurance-with-pre-existing-conditions-js": () => import("./../../../src/pages/life-insurance-with-pre-existing-conditions.js" /* webpackChunkName: "component---src-pages-life-insurance-with-pre-existing-conditions-js" */),
  "component---src-pages-life-with-critical-illness-js": () => import("./../../../src/pages/life-with-critical-illness.js" /* webpackChunkName: "component---src-pages-life-with-critical-illness-js" */),
  "component---src-pages-lifestyle-voucher-terms-and-conditions-js": () => import("./../../../src/pages/lifestyle-voucher-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-lifestyle-voucher-terms-and-conditions-js" */),
  "component---src-pages-mortgage-critical-illness-cover-js": () => import("./../../../src/pages/mortgage-critical-illness-cover.js" /* webpackChunkName: "component---src-pages-mortgage-critical-illness-cover-js" */),
  "component---src-pages-mortgage-critical-illness-insurance-js": () => import("./../../../src/pages/mortgage-critical-illness-insurance.js" /* webpackChunkName: "component---src-pages-mortgage-critical-illness-insurance-js" */),
  "component---src-pages-mortgage-critical-illness-js": () => import("./../../../src/pages/mortgage-critical-illness.js" /* webpackChunkName: "component---src-pages-mortgage-critical-illness-js" */),
  "component---src-pages-mortgage-critical-illness-life-js": () => import("./../../../src/pages/mortgage-critical-illness-life.js" /* webpackChunkName: "component---src-pages-mortgage-critical-illness-life-js" */),
  "component---src-pages-mortgage-lifeinsurance-js": () => import("./../../../src/pages/mortgage-lifeinsurance.js" /* webpackChunkName: "component---src-pages-mortgage-lifeinsurance-js" */),
  "component---src-pages-mortgage-protection-life-insurance-js": () => import("./../../../src/pages/mortgage-protection-life-insurance.js" /* webpackChunkName: "component---src-pages-mortgage-protection-life-insurance-js" */),
  "component---src-pages-mums-life-insurance-js": () => import("./../../../src/pages/mums-life-insurance.js" /* webpackChunkName: "component---src-pages-mums-life-insurance-js" */),
  "component---src-pages-new-parent-life-insurance-js": () => import("./../../../src/pages/new-parent-life-insurance.js" /* webpackChunkName: "component---src-pages-new-parent-life-insurance-js" */),
  "component---src-pages-over-50-critical-illness-cover-js": () => import("./../../../src/pages/over50-critical-illness-cover.js" /* webpackChunkName: "component---src-pages-over-50-critical-illness-cover-js" */),
  "component---src-pages-over-50-critical-illness-insurance-js": () => import("./../../../src/pages/over50-critical-illness-insurance.js" /* webpackChunkName: "component---src-pages-over-50-critical-illness-insurance-js" */),
  "component---src-pages-over-50-critical-illness-js": () => import("./../../../src/pages/over50-critical-illness.js" /* webpackChunkName: "component---src-pages-over-50-critical-illness-js" */),
  "component---src-pages-over-50-critical-illness-life-js": () => import("./../../../src/pages/over50-critical-illness-life.js" /* webpackChunkName: "component---src-pages-over-50-critical-illness-life-js" */),
  "component---src-pages-over-50-funeral-cover-js": () => import("./../../../src/pages/over-50-funeral-cover.js" /* webpackChunkName: "component---src-pages-over-50-funeral-cover-js" */),
  "component---src-pages-over-50-life-insurance-js": () => import("./../../../src/pages/over-50-life-insurance.js" /* webpackChunkName: "component---src-pages-over-50-life-insurance-js" */),
  "component---src-pages-over-50-s-life-cover-js": () => import("./../../../src/pages/over-50s-life-cover.js" /* webpackChunkName: "component---src-pages-over-50-s-life-cover-js" */),
  "component---src-pages-over-50-s-life-insurance-js": () => import("./../../../src/pages/over-50s-life-insurance.js" /* webpackChunkName: "component---src-pages-over-50-s-life-insurance-js" */),
  "component---src-pages-over-50-s-lifeinsurance-js": () => import("./../../../src/pages/over50s-lifeinsurance.js" /* webpackChunkName: "component---src-pages-over-50-s-lifeinsurance-js" */),
  "component---src-pages-over-50-s-plan-js": () => import("./../../../src/pages/over-50s-plan.js" /* webpackChunkName: "component---src-pages-over-50-s-plan-js" */),
  "component---src-pages-over-60-life-insurance-js": () => import("./../../../src/pages/over-60-life-insurance.js" /* webpackChunkName: "component---src-pages-over-60-life-insurance-js" */),
  "component---src-pages-over-fifty-life-insurance-js": () => import("./../../../src/pages/over-fifty-life-insurance.js" /* webpackChunkName: "component---src-pages-over-fifty-life-insurance-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-protect-my-family-js": () => import("./../../../src/pages/protect-my-family.js" /* webpackChunkName: "component---src-pages-protect-my-family-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-secure-my-family-js": () => import("./../../../src/pages/secure-my-family.js" /* webpackChunkName: "component---src-pages-secure-my-family-js" */),
  "component---src-pages-seniors-life-insurance-js": () => import("./../../../src/pages/seniors-life-insurance.js" /* webpackChunkName: "component---src-pages-seniors-life-insurance-js" */),
  "component---src-pages-serious-illness-cover-js": () => import("./../../../src/pages/serious-illness-cover.js" /* webpackChunkName: "component---src-pages-serious-illness-cover-js" */),
  "component---src-pages-term-life-js": () => import("./../../../src/pages/term-life.js" /* webpackChunkName: "component---src-pages-term-life-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-veriformprequal-js": () => import("./../../../src/pages/veriformprequal.js" /* webpackChunkName: "component---src-pages-veriformprequal-js" */),
  "component---src-pages-vitality-serious-illness-js": () => import("./../../../src/pages/vitality-serious-illness.js" /* webpackChunkName: "component---src-pages-vitality-serious-illness-js" */),
  "component---src-pages-whole-of-life-insurance-js": () => import("./../../../src/pages/whole-of-life-insurance.js" /* webpackChunkName: "component---src-pages-whole-of-life-insurance-js" */)
}

